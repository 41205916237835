import React from 'react';
import {Section} from '../../LandingPage.styles';

const WhatWeDo = (props) => (
  <Section>
    What we do
  </Section>
);

export default WhatWeDo;
